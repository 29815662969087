import { Box, Button, Typography } from "@mui/material";
import { useRouter } from "next/router";
import { useContext } from "react";
import { LocalizationContext } from "../src/lib/context";

export default function Custom404() {
  const { localeStrings } = useContext(LocalizationContext);
  const router = useRouter();
  return (
    <Box
      my={20}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 2,
      }}
    >
      <Typography variant="h4" textAlign="center">
        {localeStrings.uiGeneral.pageNotFoundTitle}
      </Typography>
      <Box className="flex justify-content-center flex-wrap my-large gap-large">
        <Button variant="contained" onClick={() => router.push("/")}>
          {localeStrings.uiGeneral.goToHomepageButton}
        </Button>
        <Button onClick={() => router.push("/droners")}>
          {localeStrings.uiGeneral.searchDronePilotsButton}
        </Button>
      </Box>
    </Box>
  );
}
